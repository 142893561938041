import { Option } from '../types'
import { ProjectColumnName } from './types'
import {
  makeColorEnum,
  makeEnumString,
  makeStateEnum,
  makeStatusEnum,
  treegridDateFormat,
  treegridDateTimeFormat,
} from '../utils'
import {
  getCustomerOptions,
  getMembershipOptions,
  getOptionId,
  getOptionName,
  getSupplierOptions,
  getWorkspaceOptions,
} from '../../options/utils'
import { TreeGridTranslations } from '../hooks/use-translations'
import { DateTimeService } from '../../services/date-time-service'
import { addDays, format, previousDay } from 'date-fns'
import { forEach } from 'lodash'
import { makeZooms } from '../task-tree-grid/zooms'
import { TimeZoneType } from '../../constants/timezones'

export function makeColumns({
  defaultVisibleColumns,
  defaultColumnOrder,
  options,
  translations,
  showGantt,
  timeZone,
  weekendDays,
  dateFormat,
}: MakeColumnProps): {
  columns: Column[]
  rightColumns: Column[]
} {
  const statusEnum = makeStatusEnum(translations)
  const stateEnum = makeStateEnum(translations)

  const customerOptions = getCustomerOptions(options)
  const customerNames = customerOptions.map(getOptionName)
  const customerIds = customerOptions.map(getOptionId)
  const customersEnum = makeEnumString(customerNames)
  const customersEnumKeys = makeEnumString(customerIds)

  const membershipOptions = getMembershipOptions(options)
  const membershipNames = membershipOptions.map(getOptionName)
  const membershipIds = membershipOptions.map(getOptionId)
  const membershipEnum = makeEnumString(membershipNames)
  const membershipEnumKeys = makeEnumString(membershipIds)

  const supplierOptions = getSupplierOptions(options)
  const supplierNames = supplierOptions.map(getOptionName)
  const supplierIds = supplierOptions.map(getOptionId)
  const suppliersEnum = makeEnumString(supplierNames)
  const suppliersEnumKeys = makeEnumString(supplierIds)

  const workspaceOptions = getWorkspaceOptions(options)
  const workspaceNames = workspaceOptions.map(getOptionName)
  const workspaceIds = workspaceOptions.map(getOptionId)
  const workspacesEnum = makeEnumString(workspaceNames)
  const workspacesEnumKeys = makeEnumString(workspaceIds)

  const ganttBarColorEnum = makeColorEnum(translations)

  const hasCustomerOptions: boolean = customerOptions.length > 0
  const hasMembershipOptions: boolean = membershipOptions.length > 0
  const hasSupplierOptions: boolean = supplierOptions.length > 0
  const hasWorkspaceOptions: boolean = workspaceOptions.length > 0

  const dateTimeService = new DateTimeService({
    timeZone,
    dateFormat,
  })
  const dateTimeFormat = dateTimeService.getFormat()
  const today = new Date()
  const { zoomList, defaultZoom } = makeZooms({ translations, enableTimeComponent: false })

  const openColumn = {
    Name: 'open',
    Type: 'Icon',
    Visible: defaultVisibleColumns.includes('open'),
    Width: 32,
    CanExport: 0,
    CanPrint: 0,
    CanSort: 0,
    CanHide: 0,
    header: '',
    Class: 'openButton',
  }

  const projectNumberColumn = {
    Name: 'projectNumber',
    MinWidth: 80,
    MaxWidth: 80,
    CaseSensitive: 0,
    header: translations.rowNumberColumnHeader,
    Visible: defaultVisibleColumns.includes('projectNumber'),
  }

  const titleColumn = {
    Name: 'title',
    MinWidth: 160,
    RelWidth: 1,
    CaseSensitive: 0,
    header: translations.titleColumnHeader,
    Visible: defaultVisibleColumns.includes('title'),
  }

  const descriptionColumn = {
    Name: 'description',
    Visible: defaultVisibleColumns.includes('description'),
    CaseSensitive: 0,
    Type: 'Lines',
    MinWidth: 200,
    AcceptEnters: 1,
    header: translations.descriptionColumnHeader,
    CanSort: 0,
  }

  const plannedStartDateColumn = {
    Name: 'plannedStartDate',
    Type: 'Date',
    Visible: defaultVisibleColumns.includes('plannedStartDate'),
    header: translations.plannedStartDateColumnHeader,
    Width: 154,
  }

  const plannedEndDateColumn = {
    Name: 'plannedEndDate',
    Type: 'Date',
    Width: 154,
    Visible: defaultVisibleColumns.includes('plannedEndDate'),
    header: translations.plannedEndDateColumnHeader,
  }

  const actualStartDateColumn = {
    Name: 'actualStartDate',
    Type: 'Date',
    Width: 154,
    Visible: defaultVisibleColumns.includes('actualStartDate'),
    header: translations.actualStartDateColumnHeader,
  }

  const actualEndDateColumn = {
    Name: 'actualEndDate',
    Type: 'Date',
    Visible: defaultVisibleColumns.includes('actualEndDate'),
    Width: 154,
    header: translations.actualEndDateColumnHeader,
  }

  const actualBarStartDateColumn = {
    Name: 'actualBarStartDate',
    Type: 'Date',
    Visible: 0,
    CanHide: 0,
    CanExport: 0,
    CanPrint: 0,
    header: 'HiddenActualBarStartDate',
  }

  const actualBarEndDateColumn = {
    Name: 'actualBarEndDate',
    Type: 'Date',
    Visible: 0,
    CanHide: 0,
    CanExport: 0,
    CanPrint: 0,
    header: 'HiddenActualBarEndDate',
  }

  const rejectedDateColumn = {
    Name: 'rejectedDate',
    Type: 'Date',
    Visible: defaultVisibleColumns.includes('rejectedDate'),
    Width: 154,
    header: translations.rejectedDateColumnHeader,
    CanEdit: 0,
  }

  const statusColumn = {
    Name: 'status',
    Type: 'Enum',
    Enum: statusEnum,
    MinWidth: 120,
    Visible: defaultVisibleColumns.includes('status'),
    header: translations.statusColumnHeader,
  }

  const stateColumn = {
    Name: 'state',
    Type: 'Enum',
    Enum: stateEnum,
    CanSort: 0,
    CanExport: 0,
    MinWidth: 64,
    MaxWidth: 64,
    Visible: defaultVisibleColumns.includes('state'),
    header: translations.stateColumnHeader,
  }

  const statusDescriptionColumn = {
    Name: 'statusDescription',
    Visible: defaultVisibleColumns.includes('statusDescription'),
    CaseSensitive: 0,
    Type: 'Lines',
    MinWidth: 200,
    RelWidth: 1,
    AcceptEnters: 1,
    header: translations.statusDescriptionColumnHeader,
    CanSort: 0,
  }

  const customersColumn = {
    Name: 'customers',
    Type: 'Enum',
    Value: 0,
    Range: true,
    MinWidth: 120,
    CanHide: hasCustomerOptions ? 1 : 0,
    Visible: hasCustomerOptions && defaultVisibleColumns.includes('customers'),
    header: translations.customersColumnHeader,
    Enum: customersEnum,
    EnumKeys: customersEnumKeys,
  }

  const managersColumn = {
    Name: 'managers',
    Type: 'Enum',
    Value: 0,
    Range: true,
    MinWidth: 120,
    CanHide: hasMembershipOptions ? 1 : 0,
    Visible: defaultVisibleColumns.includes('managers') && hasMembershipOptions ? 1 : 0,
    header: translations.managersColumnHeader,
    Enum: membershipEnum,
    EnumKeys: membershipEnumKeys,
  }

  const participantsColumn = {
    Name: 'participants',
    Type: 'Enum',
    Value: 0,
    Range: true,
    MinWidth: 120,
    CanHide: hasMembershipOptions ? 1 : 0,
    Visible: defaultVisibleColumns.includes('participants') && hasMembershipOptions ? 1 : 0,
    header: translations.participantsColumnHeader,
    Enum: membershipEnum,
    EnumKeys: membershipEnumKeys,
  }

  const suppliersColumn = {
    Name: 'suppliers',
    Type: 'Enum',
    Value: 0,
    Range: true,
    MinWidth: 120,
    CanHide: hasSupplierOptions ? 1 : 0,
    Visible: hasSupplierOptions && defaultVisibleColumns.includes('suppliers'),
    header: translations.suppliersColumnHeader,
    Enum: suppliersEnum,
    EnumKeys: suppliersEnumKeys,
  }

  const workspacesColumn = {
    Name: 'workspaces',
    Type: 'Enum',
    Value: 0,
    Range: true,
    MinWidth: 120,
    CanHide: hasWorkspaceOptions ? 1 : 0,
    Visible: hasWorkspaceOptions && defaultVisibleColumns.includes('workspaces'),
    header: translations.workspacesColumnHeader,
    Enum: workspacesEnum,
    EnumKeys: workspacesEnumKeys,
  }

  const ganttBarColorColumn = {
    Name: 'ganttBarColor',
    Type: 'Enum',
    CanFilter: 1,
    Enum: ganttBarColorEnum,
    MinWidth: 120,
    Visible: defaultVisibleColumns.includes('ganttBarColor'),
    header: translations.color,
  }

  const ganttGanttHtmlRightColumn = {
    Name: 'ganttGanttHtmlRight',
    Visible: 0,
    CanHide: 0,
    CanExport: 0,
    CanPrint: 0,
    header: 'HiddenGanttGanttHtmlRight',
  }

  const ganttRunColumn = {
    Name: 'RUN',
    type: 'Text',
    header: 'HiddenGanttRun',
    Visible: 0,
    CanHide: 0,
  }

  // Color weekend days differently in the gantt chart
  // If its organisation gantt chart, get the weekend days from the organisation
  // else use the weekend days from the user
  let GanttBackground = `d#${format(today, dateTimeFormat)}#1;`
  if (weekendDays && weekendDays.length) {
    forEach(weekendDays, (day) => {
      const ganttExcludeDay = previousDay(today, day)
      const ganttExcludeStart = format(ganttExcludeDay, treegridDateFormat)
      const ganttExcludeEnd = format(addDays(ganttExcludeDay, 1), treegridDateFormat)
      GanttBackground += `w#${ganttExcludeStart}~${ganttExcludeEnd}#1;` //
    })
  }

  const ganttColumn = {
    Name: 'Gantt',
    Type: 'Gantt',
    MenuName: translations.ganttChartLabel,
    CanHide: 1,
    GanttEdit: '', // Disables editing of the Gantt chart
    GanttCount: 1, // Plan bar and actual bar
    /** ---------------------------------------------------------------- */
    /** -------------------- Gantt background configuration ------------ */
    /** ---------------------------------------------------------------- */
    GanttLines: `0#${format(today, treegridDateTimeFormat)}#Navy`, // line indicating today
    GanttBackground, // background lines repeating every day and every weekend
    /** ---------------------------------------------------------------- */
    /** -------------------- Gantt zoom configuration ------------------ */
    /** ---------------------------------------------------------------- */
    GanttZoom: defaultZoom,
    GanttZoomList: zoomList,
    GanttSmoothZoom: 1, // controls whether to adjust the GanttWidth of each column with zoom
    /** ---------------------------------------------------------------- */
    /** -------------------- Gantt page configuration ------------------ */
    /** ---------------------------------------------------------------- */
    GanttPaging: 1,
    GanttPageWidth: '120%',
    /** ---------------------------------------------------------------- */
    /** -------------------- Plan Bar configuration -------------------- */
    /** ---------------------------------------------------------------- */
    GanttStart: 'plannedStartDate',
    GanttEnd: 'plannedEndDate',
    GanttHeight: 16,
    GanttClass: 'Blue',
    GanttRun: 'RUN',
    GanttRunClass: 'Group',
  }

  if (showGantt) {
    const GanttNumber = ganttColumn.GanttCount
    Object.assign(ganttColumn, {
      /** Reset gantt count */
      GanttCount: GanttNumber + 1,
      /** ------------------------------------------------------------------ */
      /** -------------------- Actual Bar configuration -------------------- */
      /** ------------------------------------------------------------------ */
      [`GanttStart${GanttNumber}`]: 'actualBarStartDate',
      [`GanttEnd${GanttNumber}`]: 'actualBarEndDate',
      [`GanttTop${GanttNumber}`]: 0,
      [`GanttHeight${GanttNumber}`]: 3,
      [`GanttClass${GanttNumber}`]: 'Gray',
    })
  }

  const columnConfigByColumnName: Record<ProjectColumnName, Column> = {
    open: openColumn,
    projectNumber: projectNumberColumn,
    title: titleColumn,
    description: descriptionColumn,
    plannedStartDate: plannedStartDateColumn,
    plannedEndDate: plannedEndDateColumn,
    actualStartDate: actualStartDateColumn,
    actualEndDate: actualEndDateColumn,
    actualBarStartDate: actualBarStartDateColumn,
    actualBarEndDate: actualBarEndDateColumn,
    rejectedDate: rejectedDateColumn,
    status: statusColumn,
    state: stateColumn,
    statusDescription: statusDescriptionColumn,
    managers: managersColumn,
    participants: participantsColumn,
    suppliers: suppliersColumn,
    workspaces: workspacesColumn,
    customers: customersColumn,
    ganttGanttHtmlRight: ganttGanttHtmlRightColumn,
    ganttBarColor: ganttBarColorColumn,
    ganttRun: ganttRunColumn,
  }

  const columns = defaultColumnOrder
    .map((columnName) => columnConfigByColumnName[columnName])
    .concat({ Name: 'Panel', Visible: 0, CanHide: 0 })

  const makeRightCols = () => {
    const rightColumns = [] as any[]
    if (showGantt) {
      rightColumns.push(ganttColumn)
    }
    return rightColumns
  }

  return { columns, rightColumns: makeRightCols() }
}

type MakeColumnProps = {
  defaultVisibleColumns: ProjectColumnName[]
  defaultColumnOrder: ProjectColumnName[]
  options: Option[]
  translations: TreeGridTranslations
  weekendDays: DayOfWeek[]
  timeZone: TimeZoneType
  dateFormat: string
  showGantt: boolean
}

type Column = Partial<TCol> & {
  header?: string
}
