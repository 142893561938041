import { TimeZoneType } from '../../constants/timezones'
import { IOptionNormalized } from '../../options/interfaces/options-normalized'
import {
  getCustomerOptions,
  getMembershipOptions,
  getOptionId,
  getOptionName,
  getWorkspaceOptions,
} from '../../options/utils'
import { DateTimeService } from '../../services/date-time-service'
import { getColorHtmlForColor, makeEnumString, mapColorToTranslations } from '../utils'
import { TreeGridTodo } from './types'
import { isTodoDueDatePastDue } from '../../todos/utils/is-past-due'
import { TodoViewModel } from '../../todos/api/todo'
import { getTitleHtmlPrefix } from '../utils/tree-grid'
import { TreeGridTranslations } from '../hooks/use-translations'

export const makeTreegridTodoRows = ({
  todos,
  dateFormat,
  timeZone,
  options,
  viewInfo,
  translations,
}: {
  todos: TodoViewModel[]
  dateFormat: string
  timeZone: TimeZoneType
  options: IOptionNormalized[]
  translations: TreeGridTranslations
  viewInfo?: { projectId: string | null; taskId: string | null }
}): TreeGridTodo[] => {
  return todos.map((todo) => makeTreegridTodoRow({ todo, dateFormat, timeZone, options, viewInfo, translations }))
}

export const makeTreegridTodoRow = ({
  todo,
  dateFormat,
  timeZone,
  options,
  viewInfo,
  translations,
}: {
  todo: TodoViewModel
  dateFormat: string
  timeZone: TimeZoneType
  options: IOptionNormalized[]
  translations: TreeGridTranslations
  viewInfo?: { projectId: string | null; taskId: string | null }
}): TreeGridTodo => {
  const filterOptionByOrganisation = makeFilterOptionByOrganisation(todo)
  const { id, title, description, enableTimeComponent, createdAt, projectLink, taskLink, ancestorTaskLinks } = todo
  const dateTimeService = new DateTimeService({ dateFormat, timeZone, enableTimeComponent })

  const titleWrap = 1
  const titleHtmlPrefix = getTodoTitleHtmlPrefix()
  const translatedColor = mapColorToTranslations(translations)[todo.ganttBarColor]
  const ganttBarColor = getColorHtmlForColor({ color: todo.ganttBarColor, translatedColor })

  const dueDate = getDueDate()
  const customers = todo.customers.map((c) => c.id).join(';')
  const workspaces = todo.workspaces.map((w) => w.id).join(';')
  const responsible = todo.responsible.map((r) => r.id).join(';')

  const customerOptions = getCustomerOptions(options).filter(filterOptionByOrganisation)
  const customerNames = customerOptions.map(getOptionName)
  const customerIds = customerOptions.map(getOptionId)
  const deactivatedCustomers = todo.customers.filter(({ id }) => !customerIds.includes(id))
  const deactivatedCustomersNames = deactivatedCustomers.map(({ name }) => name)
  const deactivatedCustomersIds = deactivatedCustomers.map(({ id }) => id)
  const customersEnum = makeEnumString([...customerNames, ...deactivatedCustomersNames])
  const customersEnumKeys = makeEnumString([...customerIds, ...deactivatedCustomersIds])

  const workspaceOptions = getWorkspaceOptions(options).filter(filterOptionByOrganisation)
  const workspaceNames = workspaceOptions.map(getOptionName)
  const workspaceIds = workspaceOptions.map(getOptionId)
  const deactivatedWorkspaces = todo.workspaces.filter(({ id }) => !workspaceIds.includes(id))
  const deactivatedWorkspacesNames = deactivatedWorkspaces.map(({ name }) => name)
  const deactivatedWorkspacesIds = deactivatedWorkspaces.map(({ id }) => id)
  const workspacesEnum = makeEnumString([...workspaceNames, ...deactivatedWorkspacesNames])
  const workspacesEnumKeys = makeEnumString([...workspaceIds, ...deactivatedWorkspacesIds])

  const responsibleOptions = getMembershipOptions(options).filter(filterOptionByOrganisation)
  const responsibleNames = responsibleOptions.map(getOptionName)
  const responsibleIds = responsibleOptions.map(getOptionId)
  const deactivatedResponsible = todo.responsible.filter(({ id }) => !responsibleIds.includes(id))
  const deactivatedResponsibleNames = deactivatedResponsible.map(({ name }) => name)
  const deactivatedResponsibleIds = deactivatedResponsible.map(({ id }) => id)
  const responsibleEnum = makeEnumString([...responsibleNames, ...deactivatedResponsibleNames])
  const responsibleEnumKeys = makeEnumString([...responsibleIds, ...deactivatedResponsibleIds])

  return {
    id,
    createdAt: new Date(createdAt).getTime(),
    titleWrap,
    titleHtmlPrefix,
    title,
    description,
    dueDate,
    dueDateFormat: dateTimeService.getFormat(),
    dueDateClass: getDueDateClass(),
    customers,
    workspaces,
    responsible,
    ganttBarColor,
    ganttBarColorFilterValue: translatedColor,
    completed: todo.completed ? 1 : 0,
    enableTimeComponent: todo.enableTimeComponent ? 1 : 0,
    customersEnum,
    customersEnumKeys,
    workspacesEnum,
    workspacesEnumKeys,
    responsibleEnum,
    responsibleEnumKeys,
    CanEdit: todo.canUpdate ? 1 : 0,
    CanDelete: todo.canDelete ? 1 : 0,
    ganttBarColorCanEdit: todo.canUpdate ? 1 : 0,
    responsibleCanEdit: todo.canUpdate && responsibleOptions.length > 0 ? 1 : 0,
    customersCanEdit: todo.canUpdate && customerOptions.length > 0 ? 1 : 0,
    workspacesCanEdit: todo.canUpdate && workspaceOptions.length > 0 ? 1 : 0,
    Height: 48,
    MaxHeight: 48,
  }

  function getDueDate(): number | string {
    return todo.dueDate ? dateTimeService.removeTimezoneOffset(todo.dueDate, 'UTC').getTime() : ''
  }

  function getDueDateClass(): string {
    const isPastDue = isTodoDueDatePastDue({ todo, dateTimeService })
    return isPastDue ? 'redText' : ''
  }

  function getTodoTitleHtmlPrefix(): string {
    const projectOrTaskNumber = todo.task?.taskNumber || todo.project?.projectNumber
    let projectLinkToShow = projectLink
      ? { ...projectLink, label: `${projectOrTaskNumber}: ${projectLink.label}` }
      : null
    let taskLinkToShow = taskLink
    let ancestorTaskLinksToShow = ancestorTaskLinks

    if (viewInfo) {
      const isProjectView = viewInfo.projectId
      const isTaskView = viewInfo.taskId
      const isAncestorTaskView = viewInfo.taskId !== todo.task?.id
      if (isProjectView) projectLinkToShow = null
      if (isTaskView && !isAncestorTaskView) {
        projectLinkToShow = null
        ancestorTaskLinksToShow = []
        taskLinkToShow = null
      }
      if (isTaskView && isAncestorTaskView) {
        const taskViewId = viewInfo.taskId as string
        projectLinkToShow = null
        const indexOfAncestorTask = ancestorTaskLinks.findIndex((link) => link.href.includes(taskViewId))
        ancestorTaskLinksToShow = ancestorTaskLinks.slice(indexOfAncestorTask + 1)
      }
    }

    return getTitleHtmlPrefix({
      projectLink: projectLinkToShow,
      taskLink: taskLinkToShow,
      ancestorTaskLinks: ancestorTaskLinksToShow,
    })
  }
}

function makeFilterOptionByOrganisation(todo: TodoViewModel) {
  return function (option: IOptionNormalized) {
    return option.orgId === todo.organisation?.id
  }
}
