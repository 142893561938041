import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormGroup from '@material-ui/core/FormGroup'
import Typography from '@material-ui/core/Typography'
import { makeStyles, type Theme } from '@material-ui/core/styles'
import { type ReminderViewModel } from '../api/reminder'
import { type ReminderRecipientRole } from '../api/reminder-response'
import { useTranslations } from '../hooks/use-translations'
import { useReminderMutations } from '../hooks/use-reminder-mutations'

const PROJECT_ROLE_RECIPIENTS: ReminderRecipientRole[] = ['projectManager', 'projectParticipants']
const TASK_ROLE_RECIPIENTS: ReminderRecipientRole[] = [
  'taskManager',
  'taskParticipants',
  'managerOfParentTask',
  'managersOfDependentTasks',
  'projectManager',
]
const CALENDAR_EVENT_ROLE_RECIPIENTS: ReminderRecipientRole[] = ['calendarEventParticipants']
const TODO_ROLE_RECIPIENTS: ReminderRecipientRole[] = ['todoResponsible']

const ReminderRecipients = ({ reminder }: ReminderRecipientsProps) => {
  const classes = useStyles()
  const translations = useTranslations()
  const { updateReminderRecipients } = useReminderMutations()

  const roleRecipientOptions = reminder.isCalendarEventReminder
    ? CALENDAR_EVENT_ROLE_RECIPIENTS
    : reminder.isTaskReminder
    ? TASK_ROLE_RECIPIENTS
    : reminder.isTodoReminder
    ? TODO_ROLE_RECIPIENTS
    : PROJECT_ROLE_RECIPIENTS

  const toggleRoleRecipient = (role: ReminderRecipientRole) => {
    const prevRoles = reminder.recipients.roles
    const isCurrentlyChecked = prevRoles.includes(role)
    const nextRoles = isCurrentlyChecked ? prevRoles.filter((r) => r !== role) : [...prevRoles, role]
    updateReminderRecipients(reminder.id, { roles: nextRoles })
  }

  if (reminder.isPersonal) return null

  return (
    <FormGroup className={classes.formGroup}>
      <Typography variant="subtitle2" component="legend" gutterBottom>
        {translations.roleRecipients}
      </Typography>
      {roleRecipientOptions.map((role) => (
        <FormControlLabel
          label={translations[role]}
          control={
            <Checkbox
              color="primary"
              checked={reminder.recipients.roles.includes(role)}
              onChange={() => toggleRoleRecipient(role)}
              disabled={checkIsDisabled(reminder, role)}
            />
          }
        />
      ))}
    </FormGroup>
  )
}

export default ReminderRecipients

const useStyles = makeStyles((theme: Theme) => ({
  formGroup: {
    margin: theme.spacing(1.5, 0),
  },
  h6FormLabel: {
    fontSize: 20,
    fontWeight: 500,
    lineHeight: 1.6,
    letterSpacing: '0.15px',
  },
}))

const checkIsDisabled = ({ recipients, ...reminder }: ReminderViewModel, field: ReminderRecipientRole | 'email') => {
  if (reminder.isReadOnly) return true
  const hasSingleRecipient = [...recipients.roles, ...recipients.emails].length === 1
  const isProjectManagerChecked = recipients.roles.includes('projectManager')
  const isProjectParticipantsChecked = recipients.roles.includes('projectParticipants')
  const isTaskManagerChecked = recipients.roles.includes('taskManager')
  const isTaskParticipantsChecked = recipients.roles.includes('taskParticipants')
  const isManagersOfAncestorTasksChecked = recipients.roles.includes('managerOfParentTask')
  const isManagersOfDependentTasksChecked = recipients.roles.includes('managersOfDependentTasks')
  const isCalendarEventParticipantsChecked = recipients.roles.includes('calendarEventParticipants')
  const isTodoResponsibleChecked = recipients.roles.includes('todoResponsible')

  if (field === 'projectManager') return hasSingleRecipient && isProjectManagerChecked
  if (field === 'projectParticipants') return hasSingleRecipient && isProjectParticipantsChecked
  if (field === 'taskManager') return hasSingleRecipient && isTaskManagerChecked
  if (field === 'taskParticipants') return hasSingleRecipient && isTaskParticipantsChecked
  if (field === 'managerOfParentTask') return hasSingleRecipient && isManagersOfAncestorTasksChecked
  if (field === 'managersOfDependentTasks') return hasSingleRecipient && isManagersOfDependentTasksChecked
  if (field === 'calendarEventParticipants') return hasSingleRecipient && isCalendarEventParticipantsChecked
  if (field === 'todoResponsible') return hasSingleRecipient && isTodoResponsibleChecked
  if (field === 'email') return hasSingleRecipient && reminder.hasEmailRecipients

  return reminder.isReadOnly
}

type ReminderRecipientsProps = {
  reminder: ReminderViewModel
}
