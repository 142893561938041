import { TimeZoneType } from '../../constants/timezones'
import { IOptionNormalized } from '../../options/interfaces/options-normalized'
import { DateTimeService } from '../../services/date-time-service'
import { TreeGridTask, TreeGridTaskCellPermissions } from './types'
import { sortBy } from 'lodash'
import {
  getColorHtmlForColor,
  getStateIconHtml,
  makeEnumString,
  mapColorToTranslations,
  mapStatusToTranslations,
} from '../utils'
import { getCustomerOptions, getMembershipOptions, getSupplierOptions, getWorkspaceOptions } from '../../options/utils'
import { isTaskPlannedEndDatePastDue, isTaskPlannedStartDatePastDue } from '../../tasks/utils/date'
import { TaskViewModel } from '../../tasks/api/task'
import { TreeGridTranslations } from '../hooks/use-translations'
import { getGanttGanttHtmlRight, getTitleHtmlPrefix } from '../utils/tree-grid'

export const makeTreeGridTaskRows = ({
  tasks,
  dateFormat,
  timeZone,
  options,
  translations,
}: {
  tasks: TaskViewModel[]
  dateFormat: string
  timeZone: TimeZoneType
  options: IOptionNormalized[]
  translations: TreeGridTranslations
}): TreeGridTask[] => {
  return tasks.map((task) => makeTreeGridTaskRow({ task, dateFormat, timeZone, options, translations }))
}

export const makeTreeGridTaskRow = ({
  task,
  dateFormat,
  timeZone,
  options,
  translations,
}: {
  task: TaskViewModel
  dateFormat: string
  timeZone: TimeZoneType
  options: IOptionNormalized[]
  translations: TreeGridTranslations
}): TreeGridTask => {
  const { id, title, description, statusDescription, enableTimeComponent, projectLink, ancestorTaskLinks } = task
  const dateTimeService = new DateTimeService({ dateFormat, timeZone, enableTimeComponent })
  const dateTimeFormat = dateTimeService.getFormat()
  const taskNumber = task.customTaskNumber || task.taskNumber
  const status = mapStatusToTranslations(translations)[task.status]

  const customers = task.customers.map((c) => c.id).join(';')
  const participants = task.participants.map((p) => p.id).join(';')
  const managers = task.managers.map((m) => m.id).join(';')
  const suppliers = task.suppliers.map((s) => s.id).join(';')
  const workspaces = task.workspaces.map((w) => w.id).join(';')

  const titleWrap = 1
  const titleHtmlPrefix = getTitleHtmlPrefix({ projectLink, taskLink: null, ancestorTaskLinks }) // Title links to the task
  const plannedStartDate = getPlannedStartDate() // number of milliseconds since epoch, or null
  const plannedEndDate = getPlannedEndDate() // number of milliseconds since epoch, or null
  const actualStartDate = getActualStartDate() // number of milliseconds since epoch, or null
  const actualEndDate = getActualEndDate() // number of milliseconds since epoch, or null
  const actualBarStartDate = getActualBarStartDate() // number of milliseconds since epoch, or null
  const actualBarEndDate = getActualBarEndDate() // number of milliseconds since epoch, or null
  const rejectedDate = getRejectedDate() // number of milliseconds since epoch, or null
  const cellPermissions = getTreeGridTaskCellPermissions()
  const state = getStateIconHtml(task.state) // html for the state icon
  const plannedStartDateClass = getPlannedStartDateClass() // css class for rendering red text if task is past due
  const plannedEndDateClass = getPlannedEndDateClass() // css class for rendering red text if task is past due
  const statusDescriptionTip = getStatusDescriptionTip() // Text showing the user who made the last update and when it was made
  const ganttGanttHtmlRight = getGanttGanttHtmlRight({
    title: task.title,
    startDate: task.plannedStartDate,
    endDate: task.plannedEndDate,
    dateTimeService,
    showTime: enableTimeComponent,
  })
  const GanttGanttHtmlRight = '*ganttGanttHtmlRight*'
  const translatedColor = mapColorToTranslations(translations)[task.ganttBarColor]
  const ganttBarColor = getColorHtmlForColor({ color: task.ganttBarColor, translatedColor })
  const GanttGanttClass = task.ganttBarColor === 'Default' ? 'Blue' : task.ganttBarColor

  const membershipOptions = getMembershipOptions(options).filter(({ orgId }) => task.maintainerId === orgId)
  const managerNames = membershipOptions.map(({ name }) => name)
  const managerIds = membershipOptions.map(({ id }) => id)
  const deactivatedManagers = task.managers.filter(({ id }) => !managerIds.includes(id))
  const deactivatedManagersNames = deactivatedManagers.map(({ name }) => name)
  const deactivatedManagersIds = deactivatedManagers.map(({ id }) => id)
  const managersEnum = makeEnumString([...managerNames, ...deactivatedManagersNames])
  const managersEnumKeys = makeEnumString([...managerIds, ...deactivatedManagersIds])

  const participantOptions = membershipOptions.filter(({ id }) => !managers.includes(id))
  const participantNames = participantOptions.map(({ name }) => name)
  const participantIds = participantOptions.map(({ id }) => id)
  const deactivatedParticipants = task.participants.filter(({ id }) => !participantIds.includes(id))
  const deactivatedParticipantsNames = deactivatedParticipants.map(({ name }) => name)
  const deactivatedParticipantsIds = deactivatedParticipants.map(({ id }) => id)
  const participantsEnum = makeEnumString([...participantNames, ...deactivatedParticipantsNames])
  const participantsEnumKeys = makeEnumString([...participantIds, ...deactivatedParticipantsIds])

  const supplierOptions = getSupplierOptions(options).filter(({ orgId }) => task.maintainerId === orgId)
  const supplierNames = supplierOptions.map(({ name }) => name)
  const supplierIds = supplierOptions.map(({ id }) => id)
  const deactivatedSuppliers = task.suppliers.filter(({ id }) => !supplierIds.includes(id))
  const deactivatedSuppliersNames = deactivatedSuppliers.map(({ name }) => name)
  const deactivatedSuppliersIds = deactivatedSuppliers.map(({ id }) => id)
  const supplierEnum = makeEnumString([...supplierNames, ...deactivatedSuppliersNames])
  const supplierEnumKeys = makeEnumString([...supplierIds, ...deactivatedSuppliersIds])

  const workspaceOptions = getWorkspaceOptions(options).filter(({ orgId }) => task.maintainerId === orgId)
  const workspaceNames = workspaceOptions.map(({ name }) => name)
  const workspaceIds = workspaceOptions.map(({ id }) => id)
  const deactivatedWorkspaces = task.workspaces.filter(({ id }) => !workspaceIds.includes(id))
  const deactivatedWorkspacesNames = deactivatedWorkspaces.map(({ name }) => name)
  const deactivatedWorkspacesIds = deactivatedWorkspaces.map(({ id }) => id)
  const workspaceEnum = makeEnumString([...workspaceNames, ...deactivatedWorkspacesNames])
  const workspaceEnumKeys = makeEnumString([...workspaceIds, ...deactivatedWorkspacesIds])

  const customerOptions = getCustomerOptions(options).filter(({ orgId }) => task.maintainerId === orgId)
  const customerNames = customerOptions.map(({ name }) => name)
  const customerIds = customerOptions.map(({ id }) => id)
  const deactivatedCustomers = task.customers.filter(({ id }) => !customerIds.includes(id))
  const deactivatedCustomersNames = deactivatedCustomers.map(({ name }) => name)
  const deactivatedCustomersIds = deactivatedCustomers.map(({ id }) => id)
  const customerEnum = makeEnumString([...customerNames, ...deactivatedCustomersNames])
  const customerEnumKeys = makeEnumString([...customerIds, ...deactivatedCustomersIds])

  return {
    id,
    open: '/external-link-icon.svg',
    titleWrap,
    titleHtmlPrefix,
    title,
    description,
    status,
    state,
    statusDescription,
    taskNumber,
    customers,
    participants,
    managers,
    suppliers,
    workspaces,
    plannedStartDate,
    plannedEndDate,
    actualStartDate,
    actualEndDate,
    actualBarStartDate,
    actualBarEndDate,
    rejectedDate,
    plannedStartDateClass,
    plannedEndDateClass,
    statusDescriptionTip,
    enableTimeComponent,
    GanttGanttHtmlRight,
    ganttGanttHtmlRight,
    GanttGanttClass,
    ganttBarColor,
    ganttBarColorFilterValue: translatedColor,
    plannedStartDateFormat: dateTimeFormat,
    plannedEndDateFormat: dateTimeFormat,
    actualStartDateFormat: dateTimeFormat,
    actualEndDateFormat: dateTimeFormat,
    rejectedDateFormat: dateTimeFormat,
    managersEnum,
    managersEnumKeys,
    participantsEnum,
    participantsEnumKeys,
    suppliersEnum: supplierEnum,
    suppliersEnumKeys: supplierEnumKeys,
    workspacesEnum: workspaceEnum,
    workspacesEnumKeys: workspaceEnumKeys,
    customersEnum: customerEnum,
    customersEnumKeys: customerEnumKeys,
    Height: 48,
    MaxHeight: 48,
    ...cellPermissions,
  }

  function getActualStartDate(): number | string {
    return task.actualStartDate ? dateTimeService.removeTimezoneOffset(task.actualStartDate, 'UTC').getTime() : ''
  }

  function getActualEndDate(): number | string {
    return task.actualEndDate ? dateTimeService.removeTimezoneOffset(task.actualEndDate, 'UTC').getTime() : ''
  }

  function getActualBarStartDate(): number | string {
    return getActualStartDate()
  }

  function getActualBarEndDate(): number | string {
    const now = new Date().toISOString()
    return task.isInProgress ? dateTimeService.removeTimezoneOffset(now, 'UTC').getTime() : getActualEndDate()
  }

  function getPlannedStartDate(): number | string {
    return task.plannedStartDate ? dateTimeService.removeTimezoneOffset(task.plannedStartDate, 'UTC').getTime() : ''
  }

  function getPlannedEndDate(): number | string {
    return task.plannedEndDate ? dateTimeService.removeTimezoneOffset(task.plannedEndDate, 'UTC').getTime() : ''
  }

  function getRejectedDate(): number | string {
    return task.rejectedDate ? dateTimeService.removeTimezoneOffset(task.rejectedDate, 'UTC').getTime() : ''
  }

  function getPlannedStartDateClass(): string {
    const isPastDue = isTaskPlannedStartDatePastDue({ task, dateTimeService })
    return isPastDue ? 'redText' : ''
  }

  function getPlannedEndDateClass(): string {
    const isPastDue = isTaskPlannedEndDatePastDue({ task, dateTimeService })
    return isPastDue ? 'redText' : ''
  }

  function getStatusDescriptionTip() {
    const sortedUpdates = sortBy(task.statusDescriptionUpdates, 'updatedAt')
    const latestUpdate = sortedUpdates[sortedUpdates.length - 1]
    let tip = ''
    if (latestUpdate) {
      const formatStr = `${dateFormat} HH:mm`
      const { updatedBy, updatedAt } = latestUpdate
      const offsetDate = dateTimeService.removeTimezoneOffset(updatedAt)
      // if there is a latest update
      tip += updatedBy // include the user who made the update
      tip += '&nbsp;&nbsp;&bull;&nbsp;&nbsp;' // some spacing
      tip += dateTimeService.format(offsetDate, formatStr) // and formatted date of update
    }
    return tip
  }

  function getTreeGridTaskCellPermissions(): TreeGridTaskCellPermissions {
    return {
      titleCanEdit: task.canUpdateDetails ? 1 : 0,
      descriptionCanEdit: task.canUpdateDetails ? 1 : 0,
      taskNumberCanEdit: task.canUpdateDetails ? 1 : 0,
      ganttBarColorCanEdit: task.canUpdateDetails ? 1 : 0,
      plannedStartDateCanEdit: task.canUpdatePlan ? 1 : 0,
      plannedEndDateCanEdit: task.canUpdatePlan ? 1 : 0,
      statusCanEdit: task.canUpdateStatus ? 1 : 0,
      stateCanEdit: task.canUpdateStatus ? 1 : 0,
      statusDescriptionCanEdit: task.canUpdateStatus ? 1 : 0,
      actualEndDateCanEdit: task.canUpdateStatus ? 1 : 0,
      actualStartDateCanEdit: task.canUpdateStatus ? 1 : 0,
      managersCanEdit: task.canUpdateManagers ? 1 : 0,
      suppliersCanEdit: task.canUpdateSuppliers ? 1 : 0,
      workspacesCanEdit: task.canUpdateWorkspaces ? 1 : 0,
      participantsCanEdit: task.canUpdateParticipants ? 1 : 0,
      customersCanEdit: 0,
    }
  }
}
