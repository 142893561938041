import Box from '@material-ui/core/Box'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Radio from '@material-ui/core/Radio'
import Select from '@material-ui/core/Select'
import { useTranslations } from '../hooks/use-translations'
import { ReminderViewModel } from '../api/reminder'
import { useReminderMutations } from '../hooks/use-reminder-mutations'
import { makeStyles, Theme } from '@material-ui/core'

const ReminderStatusInput = ({ reminder }: ReminderStatusInputProps) => {
  const classes = useStyles()
  const translations = useTranslations()
  const { updateReminderTrigger } = useReminderMutations()
  const isHidden = reminder.isPersonal || reminder.isCalendarEventReminder || reminder.isTodoReminder

  const updateReminderTriggerStatus = (e: React.ChangeEvent<{ value: unknown }>) => {
    updateReminderTrigger(reminder.id, {
      triggerType: 'statusChange',
      triggerStatus: e.target.value as 'completed' | 'inProgress',
    })
  }

  const handleChangeTriggerType = () => {
    updateReminderTrigger(reminder.id, { triggerType: 'statusChange', triggerStatus: 'completed' })
  }

  if (isHidden) return null

  const label = translations.sendNotification + ' ' + translations.whenStatusChanges

  return (
    <Box className={classes.container}>
      <FormControlLabel
        value="statusChange"
        control={<Radio color="primary" checked={reminder.isStatusChangeReminder} onChange={handleChangeTriggerType} />}
        label={label}
        disabled={reminder.isReadOnly}
        className={classes.formControlLabel}
      />
      <Box sx={{ ml: -1, display: 'flex', alignItems: 'center' }}>
        <FormControl size="small" variant="outlined" disabled={reminder.isReadOnly}>
          <Select value={reminder.triggerStatus || 'completed'} onChange={updateReminderTriggerStatus}>
            <MenuItem value="completed">{translations.completed.toLowerCase()}</MenuItem>
            <MenuItem value="inProgress">{translations.inProgress.toLowerCase()}</MenuItem>
          </Select>
        </FormControl>
      </Box>
    </Box>
  )
}

export default ReminderStatusInput

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    padding: '8px 0',
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  formControlLabel: {
    marginLeft: 0,
    '& .MuiTypography-root': {
      marginLeft: 16,
    },
  },
}))

type ReminderStatusInputProps = {
  reminder: ReminderViewModel
}
