import api from '../../services/api-service'

import { makeOrganisationApiRoutes } from '../api-routes'
import { TimeZoneType } from '../../constants/timezones'
import type { AxiosRequestConfig } from 'axios'
import type { IOrgResponse } from '../interfaces/org-response'
const organisationApiRoutes = makeOrganisationApiRoutes()

export const createOrganisation = (organisationData: NewOrganisationData, config: AxiosRequestConfig = {}) => {
  return api.post<IOrgResponse>(organisationApiRoutes.organisations(), organisationData, config)
}

export const updateOrganisationInfo = (
  orgId: string,
  payload: OrganisationInfoUpdateData,
  config: AxiosRequestConfig = {}
) => {
  return api.put<IOrgResponse>(organisationApiRoutes.organisationItem(orgId), payload, config)
}

export const fetchOrganisations = (config: AxiosRequestConfig = {}) => {
  return api.get<IOrgResponse[]>(organisationApiRoutes.organisations(), config)
}

export const fetchOrganisation = (orgId: string, config: AxiosRequestConfig = {}) => {
  return api.get<IOrgResponse>(organisationApiRoutes.organisationItem(orgId), config)
}

export const deleteOrganisation = (orgId: string, config: AxiosRequestConfig = {}) => {
  return api.delete<boolean>(organisationApiRoutes.organisationItem(orgId), config)
}

export const fetchBillingSummary = (orgId: string, config: AxiosRequestConfig = {}) => {
  return api.get<BillingSummaryResponse>(organisationApiRoutes.orgBillingSummary(orgId), config)
}

export const fetchBillingSettings = async (orgId: string, config: AxiosRequestConfig = {}) => {
  return api.get<BillingSettingsResponse>(`/orgs/${orgId}/billing/settings`, config)
}

export const updateBillingSettings = async (
  orgId: string,
  billingSettings: UpdateBillingSettingsPayload,
  config: AxiosRequestConfig = {}
) => {
  return api.put<BillingSettingsResponse>(`/orgs/${orgId}/billing/settings`, billingSettings, config)
}

export const fetchCheckBillingInfo = async (orgId: string, config: AxiosRequestConfig = {}) => {
  return api.get<CheckBillingInfoResponse>(`/orgs/${orgId}/billing/check`, config)
}

export type UpdateBillingSettingsPayload = Partial<
  BillingAddressType | InvoicingAddressType | ContactPersonType | { agreeToPolicy: boolean }
>
export type BillingSettingsResponse = {
  billingAddress: BillingAddressType
  contactPerson: ContactPersonType
  invoicingAddress: InvoicingAddressType
  agreeToPolicy: boolean
}
export type BillingAddressType = {
  company: string
  street: string
  city: string
  postalCode: string
  country: string
}
export type ContactPersonType = {
  firstname: string
  lastname: string
  email: string
  phone: string
  language: string
}
export type InvoicingAddressType = {
  invoicingMethod: 'email' | 'eInvoice'
  invoicingEmail: string
  vatNumber: string
  invoicingReferenceNumber: string
  electronicInvoicingAddress: string
  invoicingOperatorId: string
}

export type OrganisationInfoUpdateData = {
  email?: string
  name?: string
  dateFormatSeparator?: string
  dateFormat?: string
  timeZone?: TimeZoneType
  weekendDays?: DayOfWeek[]
  firstDayOfWeek?: DayOfWeek
}

export type NewOrganisationData = {
  name: string
  timeZone?: TimeZoneType
}

export type BillingSummaryResponse = {
  subtotal: number
  total: number
  summary: Record<string, PlanSummary>
  breakdown: BillingBreakdown[]
  discounts: string[]
}

type PlanSummary = { name: string; quantity: number; price: number; total: number; subtotal: number }
type BillingBreakdown = {
  membershipId: string
  name: string
  plan: string
  price: number
  priceWithDiscount: number
}
export type CheckBillingInfoResponse = {
  billingSettingsIsFilled: boolean
  canInviteUserWithoutBillingInfo: boolean
}
