import Box from '@material-ui/core/Box'
import FormControl from '@material-ui/core/FormControl'
import FormLabel from '@material-ui/core/FormLabel'
import FormControlHelperText from '@material-ui/core/FormHelperText'
import OutlinedInput from '@material-ui/core/OutlinedInput'
import Alert from '@material-ui/lab/Alert'
import AlertTitle from '@material-ui/lab/AlertTitle'
import ReminderRelativeTimeInput from './ReminderRelativeTimeInput'
import ReminderTimeInput from './ReminderTimeInput'
import ReminderStatusInput from './ReminderStatusInput'
import ReminderRoleRecipients from './ReminderRoleRecipients'
import ReminderEmailRecipients from './ReminderEmailRecipients'
import { ReminderViewModel } from '../api/reminder'
import { alpha, makeStyles, Theme } from '@material-ui/core'
import { useReminderMutations } from '../hooks/use-reminder-mutations'
import { useTranslations } from '../hooks/use-translations'
import { useReminderSummary } from '../hooks/use-reminder-summary'

const ReminderForm = ({ reminder, refStartDate, refEndDate }: ReminderFormProps) => {
  const translations = useTranslations()
  const classes = useStyles()
  const { triggerSummary, recipientSummary } = useReminderSummary({ reminder })
  const mutations = useReminderMutations()

  const onBlurNoteInput = (e: React.FocusEvent<HTMLInputElement>) => {
    const note = e.target.value
    mutations.updateReminderNote(reminder.id, { note: note || null })
  }

  const alertMessage = reminder.isExpired ? recipientSummary : `${recipientSummary}, ${triggerSummary}`

  return (
    <Box className={classes.root}>
      <FormControl component="fieldset" fullWidth margin="normal">
        <FormLabel className={classes.h6FormLabel}>{translations.notificationTimeLabel}</FormLabel>
        <Alert className={classes.alert} variant="filled" severity={reminder.isExpired ? 'error' : 'info'}>
          <AlertTitle>{alertMessage}</AlertTitle>
        </Alert>
        <ReminderRelativeTimeInput reminder={reminder} refStartDate={refStartDate} refEndDate={refEndDate} />
        <ReminderStatusInput reminder={reminder} />
        <ReminderTimeInput reminder={reminder} />
      </FormControl>
      {reminder.isPersonal ? null : (
        <FormControl>
          <FormLabel className={classes.h6FormLabel}>{translations.notificationRecipientsLabel}</FormLabel>
          <FormControlHelperText>{translations.notificationRecipientHelperText}</FormControlHelperText>
          <ReminderRoleRecipients reminder={reminder} />
          <ReminderEmailRecipients reminder={reminder} />
        </FormControl>
      )}
      <FormControl className={classes.noteFormControl}>
        <FormLabel htmlFor="note" className={classes.h6FormLabel}>
          {translations.addNoteLabel}
        </FormLabel>
        <OutlinedInput
          id="note"
          defaultValue={reminder.note || ''}
          onBlur={onBlurNoteInput}
          disabled={reminder.isReadOnly}
          fullWidth
        />
      </FormControl>
    </Box>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    borderRadius: 12,
    background: alpha(theme.palette.primary.main, 0.075),
    padding: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },
  h6FormLabel: {
    fontSize: 20,
    fontWeight: 500,
    lineHeight: 1.6,
    letterSpacing: '0.15px',
    marginBottom: theme.spacing(1),
  },
  alert: {
    marginTop: 8,
    marginBottom: 24,
    paddingTop: 8,
    paddingBottom: 0,
    '&.MuiAlert-filledInfo': {
      color: theme.palette.common.black,
      background: alpha(theme.palette.info.main, 0.1),
    },
  },
  noteFormControl: {
    marginTop: 16,
    display: 'block',
    width: '100%',
  },
}))

export default ReminderForm

type ReminderFormProps = {
  reminder: ReminderViewModel
  refStartDate: Date | null
  refEndDate: Date | null
}
