import addDays from 'date-fns/addDays'
import Box from '@material-ui/core/Box'
import DatePicker from '../../components/DatePicker'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Radio from '@material-ui/core/Radio'
import { makeStyles, Theme } from '@material-ui/core'
import { useAuthUserLocale } from '../../users/hooks/use-auth-user-locale'
import { ReminderViewModel } from '../api/reminder'
import { DateTimeService } from '../../services/date-time-service'
import { useReminderMutations } from '../hooks/use-reminder-mutations'
import { useTranslations } from '../hooks/use-translations'

const tomorrow = addDays(new Date(), 1)

const ReminderTimeInput = ({ reminder }: ReminderTimeInputProps) => {
  const classes = useStyles()
  const translations = useTranslations()
  const { reminderTime } = reminder
  const { updateReminderTrigger } = useReminderMutations()
  const { timeZone, dateFormat, dateTimeFormat } = useAuthUserLocale()

  const dateTimeService = new DateTimeService({ timeZone, dateFormat, enableTimeComponent: true })
  const timeZonedReminderTime = dateTimeService.removeTimezoneOffset(reminderTime || tomorrow)
  const timeZonedMinDate = dateTimeService.removeTimezoneOffset(new Date())

  const updateReminderTime = (reminderTime: Date | null) => {
    if (!reminderTime) return
    const timeZonedReminderTime = dateTimeService.addTimezoneOffset(reminderTime)
    updateReminderTrigger(reminder.id, {
      triggerType: 'absoluteTime',
      reminderTime: timeZonedReminderTime.toISOString(),
    })
  }

  const handleChangeTriggerType = () => {
    updateReminderTrigger(reminder.id, {
      triggerType: 'absoluteTime',
      reminderTime: reminder.reminderTime?.toISOString?.() || tomorrow.toISOString(),
    })
  }

  const inputLabel = translations.sendNotification + ' ' + translations.at

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', padding: '8px 0' }}>
      <FormControlLabel
        value="statusChange"
        control={<Radio color="primary" checked={reminder.isAbsoluteTimeReminder} onChange={handleChangeTriggerType} />}
        label={inputLabel}
        disabled={reminder.isReadOnly}
        className={classes.formControlLabel}
      />
      <Box className={classes.reminderTimeInputContainer}>
        <DatePicker
          onChange={updateReminderTime}
          dateFormat={dateTimeFormat}
          selected={timeZonedReminderTime}
          minDate={timeZonedMinDate}
          isClearable={false}
          textFieldProps={{ inputProps: { disabled: reminder.isReadOnly } }}
          showTimeSelect
        />
      </Box>
    </Box>
  )
}

export default ReminderTimeInput

const useStyles = makeStyles((theme: Theme) => ({
  reminderTimeInputContainer: {
    marginLeft: 0,
    paddingTop: 8,
    marginBottom: 16,
    maxWidth: 220,
    '& .MuiOutlinedInput-input': {
      paddingTop: '10.5px',
      paddingBottom: '10.5px',
    },
  },
  formControlLabel: {
    marginLeft: 0,
    '& .MuiTypography-root': {
      marginLeft: 16,
    },
  },
}))

type ReminderTimeInputProps = {
  reminder: ReminderViewModel
}
