import { TimeZoneType } from '../../../constants/timezones'
import { Option } from '../../types'
import { TaskActivityRow } from '../types'
import { DateTimeService } from '../../../services/date-time-service'
import {
  getCustomerOptions,
  getMembershipOptions,
  getOptionId,
  getOptionName,
  getSupplierOptions,
  getWorkspaceOptions,
} from '../../../options/utils'
import {
  getColorHtmlForColor,
  getStateIconHtml,
  makeEnumString,
  mapColorToTranslations,
  mapStatusToTranslations,
} from '../../utils'
import { sortBy } from 'lodash'
import { isTaskPlannedEndDatePastDue, isTaskPlannedStartDatePastDue } from '../../../tasks/utils/date'
import { TaskViewModel } from '../../../tasks/api/task'
import { TreeGridTranslations } from '../../hooks/use-translations'
import { getGanttGanttHtmlRight, getTitleHtmlPrefix } from '../../utils/tree-grid'

export const makeTaskActivityRow = ({
  task,
  dateFormat,
  timeZone,
  options,
  translations,
}: MakeTaskActivityRowProps): TaskActivityRow => {
  const filterOptionByOrganisation = makeFilterOptionByOrganisation(task)
  const {
    id,
    title,
    description,
    statusDescription,
    enableTimeComponent,
    projectLink,
    ancestorTaskLinks,
    plannedEndDate,
    plannedStartDate,
  } = task
  const dateTimeService = new DateTimeService({ dateFormat, timeZone, enableTimeComponent })
  const dateTimeFormat = dateTimeService.getFormat()
  const status = mapStatusToTranslations(translations)[task.status]
  const ganttGanttHtmlRight = getGanttGanttHtmlRight({
    title: title,
    startDate: plannedStartDate,
    endDate: plannedEndDate,
    dateTimeService,
    showTime: enableTimeComponent,
  })
  const GanttGanttHtmlRight = '*ganttGanttHtmlRight*'
  const translatedColor = mapColorToTranslations(translations)[task.ganttBarColor]
  const ganttBarColor = getColorHtmlForColor({ color: task.ganttBarColor, translatedColor })
  const GanttGanttClass = task.ganttBarColor === 'Default' ? 'Blue' : task.ganttBarColor

  const customers = task.customers.map((c) => c.id).join(';')
  const customerOptions = getCustomerOptions(options).filter(filterOptionByOrganisation)
  const customerNames = customerOptions.map(getOptionName)
  const customerIds = customerOptions.map(getOptionId)
  const deactivatedCustomers = task.customers.filter(({ id }) => !customerIds.includes(id))
  const deactivatedCustomersNames = deactivatedCustomers.map(({ name }) => name)
  const deactivatedCustomersIds = deactivatedCustomers.map(({ id }) => id)
  const customersEnum = makeEnumString([...customerNames, ...deactivatedCustomersNames])
  const customersEnumKeys = makeEnumString([...customerIds, ...deactivatedCustomersIds])

  const responsible = task.managers.map((r) => r.id).join(';')
  const membershipOptions = getMembershipOptions(options).filter(filterOptionByOrganisation)
  const responsibleNames = membershipOptions.map(getOptionName)
  const responsibleIds = membershipOptions.map(getOptionId)
  const deactivatedResponsible = task.managers.filter(({ id }) => !responsibleIds.includes(id))
  const deactivatedResponsibleNames = deactivatedResponsible.map(({ name }) => name)
  const deactivatedResponsibleIds = deactivatedResponsible.map(({ id }) => id)
  const responsibleEnum = makeEnumString([...responsibleNames, ...deactivatedResponsibleNames])
  const responsibleEnumKeys = makeEnumString([...responsibleIds, ...deactivatedResponsibleIds])

  const participants = task.participants.map((r) => r.id).join(';')
  const participantOptions = membershipOptions.filter(({ id }) => !responsible.includes(id))
  const participantNames = participantOptions.map(getOptionName)
  const participantIds = participantOptions.map(getOptionId)
  const deactivatedParticipants = task.participants.filter(({ id }) => !participantIds.includes(id))
  const deactivatedParticipantsNames = deactivatedParticipants.map(({ name }) => name)
  const deactivatedParticipantsIds = deactivatedParticipants.map(({ id }) => id)
  const participantsEnum = makeEnumString([...participantNames, ...deactivatedParticipantsNames])
  const participantsEnumKeys = makeEnumString([...participantIds, ...deactivatedParticipantsIds])

  const suppliers = task.suppliers.map((s) => s.id).join(';')
  const supplierOptions = getSupplierOptions(options).filter(filterOptionByOrganisation)
  const supplierNames = supplierOptions.map(getOptionName)
  const supplierIds = supplierOptions.map(getOptionId)
  const deactivatedSuppliers = task.suppliers.filter(({ id }) => !supplierIds.includes(id))
  const deactivatedSuppliersNames = deactivatedSuppliers.map(({ name }) => name)
  const deactivatedSuppliersIds = deactivatedSuppliers.map(({ id }) => id)
  const suppliersEnum = makeEnumString([...supplierNames, ...deactivatedSuppliersNames])
  const suppliersEnumKeys = makeEnumString([...supplierIds, ...deactivatedSuppliersIds])

  const workspaces = task.workspaces.map((w) => w.id).join(';')
  const workspaceOptions = getWorkspaceOptions(options).filter(filterOptionByOrganisation)
  const workspaceNames = workspaceOptions.map(getOptionName)
  const workspaceIds = workspaceOptions.map(getOptionId)
  const deactivatedWorkspaces = task.workspaces.filter(({ id }) => !workspaceIds.includes(id))
  const deactivatedWorkspacesNames = deactivatedWorkspaces.map(({ name }) => name)
  const deactivatedWorkspacesIds = deactivatedWorkspaces.map(({ id }) => id)
  const workspacesEnum = makeEnumString([...workspaceNames, ...deactivatedWorkspacesNames])
  const workspacesEnumKeys = makeEnumString([...workspaceIds, ...deactivatedWorkspacesIds])

  return {
    id,
    type: 'task',
    open: '/external-link-icon.svg',
    activityNumber: task.customTaskNumber || task.taskNumber,
    title,
    titleHtmlPrefix: getTitleHtmlPrefix({ projectLink, ancestorTaskLinks, taskLink: null }),
    description,
    status,
    state: getStateIconHtml(task.state),
    statusDescription,
    statusDescriptionTip: getStatusDescriptionTip(),
    enableTimeComponent,
    plannedStartDate: getPlannedStartDate(),
    plannedStartDateClass: getPlannedStartDateClass(),
    plannedStartDateFormat: dateTimeFormat,
    plannedEndDate: getPlannedEndDate(),
    plannedEndDateClass: getPlannedEndDateClass(),
    plannedEndDateFormat: dateTimeFormat,
    actualStartDate: getActualStartDate(),
    actualStartDateFormat: dateTimeFormat,
    actualBarStartDate: getActualBarStartDate(),
    actualBarEndDate: getActualBarEndDate(),
    GanttGanttHtmlRight,
    ganttGanttHtmlRight,
    GanttGanttClass,
    ganttBarColor,
    ganttBarColorFilterValue: translatedColor,
    responsible,
    responsibleEnum,
    responsibleEnumKeys,
    participants,
    participantsEnum,
    participantsEnumKeys,
    customers,
    customersEnum,
    customersEnumKeys,
    suppliers,
    suppliersEnum,
    suppliersEnumKeys,
    workspaces,
    workspacesEnum,
    workspacesEnumKeys,
    activityNumberCanEdit: task.canUpdateDetails ? 1 : 0,
    titleCanEdit: task.canUpdateDetails ? 1 : 0,
    descriptionCanEdit: task.canUpdateDetails ? 1 : 0,
    ganttBarColorCanEdit: task.canUpdateDetails ? 1 : 0,
    plannedStartDateCanEdit: task.canUpdatePlan ? 1 : 0,
    plannedEndDateCanEdit: task.canUpdatePlan ? 1 : 0,
    statusCanEdit: task.canUpdateStatus ? 1 : 0,
    stateCanEdit: task.canUpdateStatus ? 1 : 0,
    statusDescriptionCanEdit: task.canUpdateStatus ? 1 : 0,
    actualStartDateCanEdit: task.canUpdateStatus ? 1 : 0,
    customersCanEdit: 0,
    responsibleCanEdit: task.canUpdateManagers && task.isOrgTask ? 1 : 0,
    participantsCanEdit: task.canUpdateParticipants && task.isOrgTask ? 1 : 0,
    suppliersCanEdit: task.canUpdateSuppliers && task.isOrgTask ? 1 : 0,
    workspacesCanEdit: task.canUpdateWorkspaces && task.isOrgTask ? 1 : 0,
    Height: 48,
    MaxHeight: 48,
  }

  function getPlannedStartDate(): number | string {
    return task.plannedStartDate ? dateTimeService.removeTimezoneOffset(task.plannedStartDate, 'UTC').getTime() : ''
  }

  function getPlannedEndDate(): number | string {
    return task.plannedEndDate ? dateTimeService.removeTimezoneOffset(task.plannedEndDate, 'UTC').getTime() : ''
  }

  function getActualStartDate(): number | string {
    return task.actualStartDate ? dateTimeService.removeTimezoneOffset(task.actualStartDate, 'UTC').getTime() : ''
  }

  function getActualBarStartDate(): number | string {
    return getActualStartDate()
  }

  function getActualBarEndDate(): number | string {
    const now = new Date().toISOString()
    return task.isInProgress ? dateTimeService.removeTimezoneOffset(now, 'UTC').getTime() : ''
  }

  function getPlannedStartDateClass(): string {
    const isPastDue = isTaskPlannedStartDatePastDue({ task, dateTimeService })
    return isPastDue ? 'redText' : ''
  }

  function getPlannedEndDateClass(): string {
    const isPastDue = isTaskPlannedEndDatePastDue({ task, dateTimeService })
    return isPastDue ? 'redText' : ''
  }

  function getStatusDescriptionTip() {
    const sortedUpdates = sortBy(task.statusDescriptionUpdates, 'updatedAt')
    const latestUpdate = sortedUpdates[sortedUpdates.length - 1]
    let tip = ''
    if (latestUpdate) {
      const formatStr = `${dateFormat} HH:mm`
      const { updatedBy, updatedAt } = latestUpdate
      const offsetDate = dateTimeService.removeTimezoneOffset(updatedAt)
      // if there is a latest update
      tip += updatedBy // include the user who made the update
      tip += '&nbsp;&nbsp;&bull;&nbsp;&nbsp;' // some spacing
      tip += dateTimeService.format(offsetDate, formatStr) // and formatted date of update
    }
    return tip
  }
}

function makeFilterOptionByOrganisation(task: TaskViewModel) {
  return function (option: Option) {
    return option.orgId === task.maintainerId
  }
}

type MakeTaskActivityRowProps = {
  task: TaskViewModel
  dateFormat: string
  timeZone: TimeZoneType
  options: Option[]
  translations: TreeGridTranslations
}
