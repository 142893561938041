import React from 'react'
import Box from '@material-ui/core/Box'
import TextField from '@material-ui/core/TextField'
import FormControl from '@material-ui/core/FormControl'
import Radio from '@material-ui/core/Radio'
import MuiSelect from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import Typography from '@material-ui/core/Typography'
import { ReminderViewModel } from '../api/reminder'
import {
  ReminderEntityType,
  ReminderTimeDetailReference,
  ReminderTimeDetails,
  ReminderTimeDetailUnit,
  ReminderTimeDetailUnitSchema,
} from '../api/reminder-response'
import { useTranslations } from '../hooks/use-translations'
import { useReminderMutations } from '../hooks/use-reminder-mutations'
import { guessReminderTime } from '../utils/guess-reminder-time'
import { isDate } from 'lodash'
import { makeStyles, Theme } from '@material-ui/core'
import { z } from 'zod'

const minuteOptions = [
  { value: 10, label: '10' },
  { value: 20, label: '20' },
  { value: 30, label: '30' },
  { value: 40, label: '40' },
  { value: 50, label: '50' },
]

const useInput = ({ reminder, refStartDate, refEndDate }: ReminderRelativeTimeInputProps) => {
  const translations = useTranslations()
  const reminderTimeDetails = getInitialReminderTimeDetails({ reminder, refStartDate, refEndDate })
  const { updateReminderTrigger } = useReminderMutations()

  const updateReminderTimeDetails = (updates: Partial<ReminderTimeDetails> = {}) => {
    const hasPlan = refStartDate || refEndDate
    if (!hasPlan) return
    const updatedReminderTimeDetails = getUpdatedReminderTimeDetails({
      prev: reminderTimeDetails,
      updates,
      refStartDate,
      refEndDate,
    })
    updateReminderTrigger(reminder.id, {
      triggerType: 'relativeTime',
      reminderTimeDetails: updatedReminderTimeDetails,
    })
  }

  const handleChangeTriggerType = () => updateReminderTimeDetails()

  const handleChangeValue = (e: React.ChangeEvent<{ value: unknown }>) => {
    const parseResult = z.coerce.number().safeParse(e.target.value)
    if (parseResult.success) {
      updateReminderTimeDetails({ value: parseResult.data })
    }
  }

  const handleChangeUnit = (e: React.ChangeEvent<{ value: unknown }>) => {
    const parseResult = ReminderTimeDetailUnitSchema.safeParse(e.target.value)
    if (parseResult.success) {
      const unit = parseResult.data
      const value = unit === 'minutes' ? 10 : unit === 'hours' && reminderTimeDetails.value > 23 ? 1 : undefined
      updateReminderTimeDetails({ unit, value })
    }
  }

  const handleChangeIsAfter = (e: React.ChangeEvent<{ value: unknown }>) => {
    const parsedResult = z
      .enum(['before', 'after'])
      .transform((v) => (v === 'after' ? true : false))
      .safeParse(e.target.value)
    if (parsedResult.success) {
      updateReminderTimeDetails({ isAfter: parsedResult.data })
    }
  }

  const handleChangeReference = (e: React.ChangeEvent<{ value: unknown }>) => {
    const parseResult = z.enum(['plannedStart', 'plannedEnd']).safeParse(e.target.value)
    if (parseResult.success) {
      updateReminderTimeDetails({ reference: parseResult.data })
    }
  }

  const unitOptions: Option[] = [
    { value: 'minutes', label: translations.minutes },
    { value: 'hours', label: translations.hours },
    { value: 'days', label: translations.days },
    { value: 'weeks', label: translations.weeks },
  ]

  const directionOptions: Option[] = [
    { value: 'before', label: translations.before },
    { value: 'after', label: translations.after },
  ]

  const referenceOptions: Option[] = [
    { value: 'plannedStart', label: translations.plannedStartDate, disabled: !refStartDate },
    { value: 'plannedEnd', label: translations.plannedEndDate, disabled: !refEndDate },
  ]

  return {
    reminderTimeDetails,
    handleChangeTriggerType,
    handleChangeValue,
    handleChangeUnit,
    handleChangeIsAfter,
    handleChangeReference,
    minuteOptions,
    unitOptions,
    directionOptions,
    referenceOptions,
    translations,
  }
}

const Select = ({ value, onChange, options, disabled }: SelectProps) => (
  <FormControl size="small" variant="outlined" disabled={disabled}>
    <MuiSelect value={value} onChange={onChange}>
      {options.map((option) => (
        <MenuItem key={option.value} value={option.value} disabled={option.disabled}>
          {option.label}
        </MenuItem>
      ))}
    </MuiSelect>
  </FormControl>
)

const ProjectOrTaskReminderRelativeTimeInput: ReminderRelativeTimeInputComponent = (props) => {
  const { reminder } = props
  const classes = useStyles()
  const {
    reminderTimeDetails,
    handleChangeTriggerType,
    handleChangeValue,
    handleChangeUnit,
    handleChangeIsAfter,
    handleChangeReference,
    minuteOptions,
    unitOptions,
    directionOptions,
    referenceOptions,
  } = useInput(props)

  return (
    <Box className={classes.container}>
      <Radio color="primary" checked={reminder.isRelativeTimeReminder} onChange={handleChangeTriggerType} />
      <Box className={classes.reminderTimeRowContainer}>
        <Box className={classes.reminderTimeRow}>
          {reminderTimeDetails.unit === 'minutes' ? (
            <Select
              value={reminderTimeDetails.value}
              onChange={handleChangeValue}
              options={minuteOptions}
              disabled={reminder.isReadOnly}
            />
          ) : (
            <TextField
              value={reminderTimeDetails.value}
              onChange={handleChangeValue}
              size="small"
              type="number"
              variant="outlined"
              className={classes.numberTextField}
              inputProps={{ min: 1, max: 23 }}
              InputProps={{ classes: { input: classes.numberInput } }}
              disabled={reminder.isReadOnly}
            />
          )}

          <Select
            value={reminderTimeDetails.unit}
            onChange={handleChangeUnit}
            options={unitOptions}
            disabled={reminder.isReadOnly}
          />
          <Select
            value={reminderTimeDetails.isAfter ? 'after' : 'before'}
            onChange={handleChangeIsAfter}
            options={directionOptions}
            disabled={reminder.isReadOnly}
          />
          <Select
            value={reminderTimeDetails.reference}
            onChange={handleChangeReference}
            options={referenceOptions}
            disabled={reminder.isReadOnly}
          />
        </Box>
      </Box>
    </Box>
  )
}

const CalendarEventReminderRelativeTimeInput: ReminderRelativeTimeInputComponent = (props) => {
  const { reminder } = props
  const classes = useStyles()
  const {
    reminderTimeDetails,
    handleChangeTriggerType,
    handleChangeValue,
    handleChangeUnit,
    unitOptions,
    translations,
  } = useInput(props)

  return (
    <Box className={classes.container}>
      <Radio color="primary" checked={reminder.isRelativeTimeReminder} onChange={handleChangeTriggerType} />
      <Box className={classes.reminderTimeRowContainer}>
        <Box className={classes.reminderTimeRow}>
          {reminderTimeDetails.unit === 'minutes' ? (
            <Select
              value={reminderTimeDetails.value}
              onChange={handleChangeValue}
              options={minuteOptions}
              disabled={reminder.isReadOnly}
            />
          ) : (
            <TextField
              value={reminderTimeDetails.value}
              onChange={handleChangeValue}
              size="small"
              type="number"
              variant="outlined"
              className={classes.numberTextField}
              inputProps={{ min: 1, max: 23 }}
              InputProps={{ classes: { input: classes.numberInput } }}
              disabled={reminder.isReadOnly}
            />
          )}
          <Select
            value={reminderTimeDetails.unit}
            onChange={handleChangeUnit}
            options={unitOptions}
            disabled={reminder.isReadOnly}
          />
          <Typography>
            {translations.before} {translations.calendarEvent.toLowerCase()}
          </Typography>
        </Box>
      </Box>
    </Box>
  )
}

const TodoReminderRelativeTimeInput: ReminderRelativeTimeInputComponent = (props) => {
  const { reminder } = props
  const classes = useStyles()
  const {
    reminderTimeDetails,
    handleChangeTriggerType,
    handleChangeValue,
    handleChangeUnit,
    unitOptions,
    translations,
  } = useInput(props)

  return (
    <Box className={classes.container}>
      <Radio color="primary" checked={reminder.isRelativeTimeReminder} onChange={handleChangeTriggerType} />
      <Box className={classes.reminderTimeRowContainer}>
        <Box className={classes.reminderTimeRow}>
          {reminderTimeDetails.unit === 'minutes' ? (
            <Select
              value={reminderTimeDetails.value}
              onChange={handleChangeValue}
              options={minuteOptions}
              disabled={reminder.isReadOnly}
            />
          ) : (
            <TextField
              value={reminderTimeDetails.value}
              onChange={handleChangeValue}
              size="small"
              type="number"
              variant="outlined"
              className={classes.numberTextField}
              inputProps={{ min: 1, max: 23 }}
              InputProps={{ classes: { input: classes.numberInput } }}
              disabled={reminder.isReadOnly}
            />
          )}
          <Select
            value={reminderTimeDetails.unit}
            onChange={handleChangeUnit}
            options={unitOptions}
            disabled={reminder.isReadOnly}
          />
          <Typography>
            {translations.before} {translations.todo.toLowerCase()} {translations.dueDate.toLowerCase()}
          </Typography>
        </Box>
      </Box>
    </Box>
  )
}

const variantComponents: { [variant in ReminderEntityType]: ReminderRelativeTimeInputComponent } = {
  project: ProjectOrTaskReminderRelativeTimeInput,
  task: ProjectOrTaskReminderRelativeTimeInput,
  calendarEvent: CalendarEventReminderRelativeTimeInput,
  todo: TodoReminderRelativeTimeInput,
}

const ReminderRelativeTimeInput = (props: ReminderRelativeTimeInputProps) => {
  const RelativeTimeInputComponent = variantComponents[props.reminder.entityType]
  const isVisible = Boolean(props.refStartDate || props.refEndDate)
  if (!isVisible) return null
  return <RelativeTimeInputComponent {...props} />
}

export default ReminderRelativeTimeInput

const getInitialReminderTimeDetails = (params: {
  reminder: ReminderViewModel
  refStartDate: Date | null
  refEndDate: Date | null
}) => {
  const { reminder, refStartDate, refEndDate } = params
  const guessedReminderTime = guessReminderTime(refStartDate, refEndDate)
  const defaultReminderTimeDetails: ReminderTimeDetails = {
    value: 1,
    unit: 'days',
    reference: refStartDate ? 'plannedStart' : 'plannedEnd',
    refDate: refStartDate || refEndDate || new Date(),
    isAfter: false,
  }
  const guessedReminderTimeDetails = isDate(guessedReminderTime) ? defaultReminderTimeDetails : guessedReminderTime
  const reminderTimeDetails: ReminderTimeDetails = reminder.reminderTimeDetails || guessedReminderTimeDetails
  return reminderTimeDetails
}

const getUpdatedReminderTimeDetails = (params: {
  prev: ReminderTimeDetails
  updates: Partial<ReminderTimeDetails>
  refStartDate: Date | null
  refEndDate: Date | null
}): ReminderTimeDetails => {
  const { prev, updates, refStartDate, refEndDate } = params
  return {
    value: updates.value !== undefined ? updates.value : prev.value,
    unit: updates.unit !== undefined ? updates.unit : prev.unit,
    reference: updates.reference !== undefined ? updates.reference : prev.reference,
    refDate: getRefDate(),
    isAfter: updates.isAfter !== undefined ? updates.isAfter : prev.isAfter,
  }

  function getRefDate() {
    if (updates.reference === 'plannedStart' && refStartDate) return refStartDate
    if (updates.reference === 'plannedEnd' && refEndDate) return refEndDate
    return prev.refDate
  }
}

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    padding: '8px 0',
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  reminderTimeRow: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 12,
    '& > .MuiFormControl-root': {
      marginRight: 12,
    },
  },
  numberTextField: {
    maxWidth: 72,
  },
  numberInput: {
    '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
      '-webkit-appearance': 'inner-spin-button',
      display: 'inline-flex',
      opacity: 1,
    },
    '&[type=number]': {
      '-moz-appearance': 'textfield',
    },
  },
  reminderTimeRowContainer: {
    marginLeft: 16,
    paddingTop: 12,
  },
}))

type ReminderRelativeTimeInputProps = {
  reminder: ReminderViewModel
  refStartDate: Date | null
  refEndDate: Date | null
}

type ReminderRelativeTimeInputComponent = React.FunctionComponent<ReminderRelativeTimeInputProps>

type SelectProps = {
  value: ReminderTimeDetailUnit | ReminderTimeDetailReference | 'before' | 'after' | number
  onChange: (e: React.ChangeEvent<{ value: unknown }>) => void
  options: Option[]
  disabled: boolean
}

type Option = {
  value: number | string
  label: string
  disabled?: boolean
}
