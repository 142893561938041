import React from 'react'
import { LinkType } from '../types/common'
import { COLOR_GRAY } from '../constants'
import { makeStyles } from '@material-ui/core'
import { useRouter } from '../hooks'
import { useUrlWithContext } from '../hooks/use-url-with-context'

type Props = {
  projectLink: LinkType | null
  taskLink: LinkType | null
  ancestorTaskLinks: LinkType[]
  projectOrTaskNumber?: string | null
  showTaskLink?: boolean
  onRedirect?: () => void
}

const FullPath: React.FC<Props> = ({
  projectLink,
  taskLink,
  ancestorTaskLinks,
  projectOrTaskNumber,
  showTaskLink = false,
  onRedirect = () => {},
}) => {
  const classes = useStyles()
  const router = useRouter()
  const { createPathWithContext } = useUrlWithContext()

  const handleClick = (href: string) => {
    const route = createPathWithContext(`${href}/basic`, { withSubContext: true })
    router.push(route)
    onRedirect()
  }

  return (
    <span>
      {projectOrTaskNumber && (
        <span className={classes.span}>
          {projectOrTaskNumber} {':'}
        </span>
      )}
      {projectLink && (
        <span
          className={classes.span}
          onClick={projectLink.disabled ? undefined : () => handleClick(projectLink.href)}
          onMouseOver={(e) => (projectLink.disabled ? '' : (e.currentTarget.style.textDecoration = 'underline'))}
          onMouseOut={(e) => (e.currentTarget.style.textDecoration = 'none')}
        >
          {projectLink.label} {' / '}
        </span>
      )}
      {ancestorTaskLinks.map((link, index) => (
        <span
          key={index}
          className={classes.span}
          onClick={link.disabled ? undefined : () => handleClick(link.href)}
          onMouseOver={(e) => (link.disabled ? '' : (e.currentTarget.style.textDecoration = 'underline'))}
          onMouseOut={(e) => (e.currentTarget.style.textDecoration = 'none')}
        >
          {link.label} {' / '}
        </span>
      ))}
      {taskLink && showTaskLink && (
        <span
          className={classes.span}
          onClick={taskLink.disabled ? undefined : () => handleClick(taskLink.href)}
          onMouseOver={(e) => (taskLink.disabled ? '' : (e.currentTarget.style.textDecoration = 'underline'))}
          onMouseOut={(e) => (e.currentTarget.style.textDecoration = 'none')}
        >
          {taskLink.label}
        </span>
      )}
    </span>
  )
}

const useStyles = makeStyles({
  span: {
    color: COLOR_GRAY,
    cursor: 'pointer',
    fontSize: '0.625rem',
    fontWeight: 400,
    overflow: 'hidden',
  },
})

export default FullPath
