import { ReminderResponseSchema } from './reminder-response'
import type { ReminderResponse } from './reminder-response'

export const makeReminderViewModel = (reminder: ReminderResponse): ReminderViewModel => {
  try {
    const parsedReminder = ReminderResponseSchema.parse(reminder)
    const { reminderTimeDetails } = parsedReminder
    const isProjectReminder = parsedReminder.entityType === 'project'
    const isTaskReminder = parsedReminder.entityType === 'task'
    const isCalendarEventReminder = parsedReminder.entityType === 'calendarEvent'
    const isTodoReminder = parsedReminder.entityType === 'todo'
    const isReadOnly = parsedReminder.sentAt !== null
    const isReminderTimeRelativeToPlannedStart = reminderTimeDetails?.reference === 'plannedStart' ?? false
    const isReminderTimeRelativeToPlannedEnd = reminderTimeDetails?.reference === 'plannedEnd' ?? false
    const isRelativeTimeReminder = parsedReminder.triggerType === 'relativeTime'
    const isAbsoluteTimeReminder = parsedReminder.triggerType === 'absoluteTime'
    const isStatusChangeReminder = parsedReminder.triggerType === 'statusChange'
    const hasEmailRecipients = parsedReminder.recipients.emails.length > 0
    const hasRoleRecipients = parsedReminder.recipients.roles.length > 0

    return {
      ...parsedReminder,
      isProjectReminder,
      isTaskReminder,
      isCalendarEventReminder,
      isTodoReminder,
      isReadOnly,
      isRelativeTimeReminder,
      isAbsoluteTimeReminder,
      isStatusChangeReminder,
      isReminderTimeRelativeToPlannedStart,
      isReminderTimeRelativeToPlannedEnd,
      hasEmailRecipients,
      hasRoleRecipients,
    }
  } catch (error) {
    throw new Error(`Failed to parse reminder: ${error}`)
  }
}

export type ReminderViewModel = ReminderResponse & {
  isProjectReminder: boolean
  isTaskReminder: boolean
  isCalendarEventReminder: boolean
  isTodoReminder: boolean
  isReadOnly: boolean
  isRelativeTimeReminder: boolean
  isAbsoluteTimeReminder: boolean
  isStatusChangeReminder: boolean
  isReminderTimeRelativeToPlannedStart: boolean
  isReminderTimeRelativeToPlannedEnd: boolean
  hasEmailRecipients: boolean
  hasRoleRecipients: boolean
}
