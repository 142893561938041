import React from 'react'
import Box from '@material-ui/core/Box'
import List from '@material-ui/core/List'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import Reminder from './Reminder'
import { ReminderViewModel } from '../api/reminder'
import { makeStyles, Theme } from '@material-ui/core'
import { cloneDeep } from 'lodash'
import { useTranslations } from '../hooks/use-translations'

const ReminderList = (props: ReminderListProps) => {
  const translations = useTranslations()
  const [expandedReminderId, setExpandedReminderId] = React.useState<string | null>(null)
  const [showOnlyActiveReminders, setShowOnlyActiveReminders] = React.useState(true)
  const sortedReminders = sortReminders(props.reminders)
  const expandedReminder = sortedReminders.find((reminder) => reminder.id === expandedReminderId)
  const activeReminders = sortedReminders.filter((reminder) => !reminder.isExpired && !reminder.sentAt)
  const hasActiveReminders = activeReminders.length > 0
  const remindersToShow = showOnlyActiveReminders && hasActiveReminders ? activeReminders : sortedReminders
  const isExpandedReminderShown = remindersToShow.some((reminder) => reminder.id === expandedReminderId)
  if (expandedReminder?.isExpired && !isExpandedReminderShown) remindersToShow.push(expandedReminder)
  const hasReminders = remindersToShow.length > 0

  const classes = useStyles({ hasReminders })

  const handleClickAddReminder = async () => {
    const createdReminder = await props.onCreateReminder()
    setExpandedReminderId(createdReminder.id)
  }

  const addNotificationButton = (
    <Button
      size={'small'}
      variant="contained"
      color="primary"
      onClick={handleClickAddReminder}
      className={classes.addNotificationButton}
    >
      {translations.addNotification}
    </Button>
  )

  const reminderListEmptyMessage = (
    <>
      <Typography variant="h6">{translations.notificationsEmpty}</Typography>
      <Box sx={{ mt: 1.5 }}>{props.canCreateReminder ? addNotificationButton : null}</Box>
    </>
  )

  return (
    <Box className={classes.listContainer}>
      {hasReminders ? null : reminderListEmptyMessage}
      {hasReminders && (
        <>
          <Box sx={{ my: 2, width: '100%' }}>
            {props.canCreateReminder && addNotificationButton}
            {hasActiveReminders && (
              <Box sx={{ marginLeft: 12, display: 'inline-block' }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      color="primary"
                      checked={showOnlyActiveReminders}
                      onChange={(e) => setShowOnlyActiveReminders(e.target.checked as boolean)}
                    />
                  }
                  label={translations.showOnlyActive}
                />
              </Box>
            )}
          </Box>
          <List className={classes.list}>
            {remindersToShow.map((reminder) => (
              <Reminder
                key={reminder.id}
                refStartDate={props.refStartDate}
                refEndDate={props.refEndDate}
                reminder={reminder}
                isExpanded={expandedReminderId === reminder.id}
                onExpand={() => setExpandedReminderId(reminder.id)}
                onCollapse={() => setExpandedReminderId(null)}
              />
            ))}
          </List>
        </>
      )}
    </Box>
  )
}

const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  listContainer: ({ hasReminders }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: hasReminders ? 'flex-start' : 'center',
    border: hasReminders ? 'none' : `1px dashed ${theme.palette.divider}`,
    padding: hasReminders ? 0 : 16,
    width: '100%',
  }),
  list: {
    width: '100%',
  },
  addNotificationButton: {
    marginRight: 12,
  },
}))

export default ReminderList

type ReminderListProps = {
  refStartDate: Date | null
  refEndDate: Date | null
  reminders: ReminderViewModel[]
  canCreateReminder: boolean
  onCreateReminder: () => Promise<ReminderViewModel>
}

type StyleProps = { hasReminders: boolean }

function sortReminders(reminders: ReminderViewModel[]): ReminderViewModel[] {
  const clonedReminders = cloneDeep(reminders)
  return clonedReminders.sort((a, b) => {
    if (a.isExpired && !b.isExpired) {
      return 1
    }

    if (!a.isExpired && b.isExpired) {
      return -1
    }

    if (a.sentAt && !b.sentAt) {
      return 1
    }

    if (!a.sentAt && b.sentAt) {
      return -1
    }

    return new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime()
  })
}
