import { useI18n } from '../../hooks'

const defaultTranslations = {
  notificationTimeLabel: 'When to notify?',
  notificationTimeSummary: 'Notification will be sent at',
  notificationExpiredSummary: 'Notification is expired.',
  minutes: 'minutes',
  hours: 'hours',
  days: 'days',
  weeks: 'weeks',
  before: 'before',
  after: 'after',
  plannedStartDate: 'planned start date',
  plannedEndDate: 'planned end date',
  notificationRecipientsLabel: 'Whom to notify?',
  notificationRecipientHelperText: 'Notification requires at least one recipient.',
  roleRecipients: 'Recipients by role',
  projectManager: 'Project Manager',
  projectParticipants: 'Project Participants',
  taskManager: 'Task responsible',
  taskParticipants: 'Task participants',
  managerOfParentTask: 'Responsible of parent task',
  managersOfDependentTasks: 'Responsible of dependent tasks',
  calendarEventParticipants: 'Calendar event participants',
  todoResponsible: 'To-do responsible',
  addNoteLabel: 'Add a note',
  completed: 'Completed',
  inProgress: 'In progress',
  notificationStatusSummary: 'Notification will be sent when status changes to {status}',
  addNotification: 'Add notification',
  notificationsEmpty: 'You have not created any notifications here',
  showOnlyActive: 'Show only active notifications',
  expired: 'Expired',
  sent: 'Sent',
  notificationWasSentTo: 'Notification was sent to',
  notificationWillBeSentTo: 'Notification will be sent to',
  deleteNotification: 'Delete notification',
  editNotification: 'Edit notification',
  emailRecipients: 'Email Recipients',
  addEmailRecipientLabel: 'Add email recipient',
  add: 'Add',
  cancel: 'Cancel',
  sendNotification: 'Send notification',
  at: 'at',
  whenStatusChanges: 'when status changes to',
  calendarEvent: 'Calendar event',
  todo: 'To-do',
  dueDate: 'Due date',
}

export const useTranslations = (defaults = defaultTranslations): Translations => {
  const { t } = useI18n('translation')

  return {
    notificationTimeLabel: t('notificationTimeLabel', defaults.notificationTimeLabel),
    notificationTimeSummary: t('notificationTimeSummary', defaults.notificationTimeSummary),
    notificationStatusSummary: t('notificationStatusSummary', defaults.notificationStatusSummary),
    notificationExpiredSummary: t('notificationExpiredSummary', defaults.notificationExpiredSummary),
    minutes: t('minutes', defaults.minutes),
    hours: t('hours', defaults.hours),
    days: t('days', defaults.days),
    weeks: t('weeks', defaults.weeks),
    before: t('before', defaults.before),
    after: t('after', defaults.after),
    plannedStartDate: t('plannedStartDate', defaults.plannedStartDate),
    plannedEndDate: t('plannedEndDate', defaults.plannedEndDate),
    notificationRecipientsLabel: t('notificationRecipientsLabel', defaults.notificationRecipientsLabel),
    roleRecipients: t('roleRecipients', defaults.roleRecipients),
    projectManager: t('projectManager', defaults.projectManager),
    projectParticipants: t('projectParticipants', defaults.projectParticipants),
    taskManager: t('taskManager', defaults.taskManager),
    taskParticipants: t('taskParticipants', defaults.taskParticipants),
    managerOfParentTask: t('managerOfParentTask', defaults.managerOfParentTask),
    managersOfDependentTasks: t('managersOfDependentTasks', defaults.managersOfDependentTasks),
    calendarEventParticipants: t('calendarEventParticipants', defaults.calendarEventParticipants),
    todoResponsible: t('todoResponsible', defaults.todoResponsible),
    addNoteLabel: t('addNoteLabel', defaults.addNoteLabel),
    notificationRecipientHelperText: t('notificationRecipientHelperText', defaults.notificationRecipientHelperText),
    completed: t('completed', defaults.completed),
    inProgress: t('inProgress', defaults.inProgress),
    addNotification: t('addNotification', defaults.addNotification),
    notificationsEmpty: t('notificationsEmpty', defaults.notificationsEmpty),
    showOnlyActive: t('showOnlyActive', defaults.showOnlyActive),
    expired: t('expired', defaults.expired),
    sent: t('sent', defaults.sent),
    notificationWasSentTo: t('notificationWasSentTo', defaults.notificationWasSentTo),
    notificationWillBeSentTo: t('notificationWillBeSentTo', defaults.notificationWillBeSentTo),
    deleteNotification: t('deleteNotification', defaults.deleteNotification),
    editNotification: t('editNotification', defaults.editNotification),
    emailRecipients: t('emailRecipients', defaults.emailRecipients),
    addEmailRecipientLabel: t('addEmailRecipientLabel', defaults.addEmailRecipientLabel),
    add: t('add', defaults.add),
    cancel: t('cancel', defaults.cancel),
    sendNotification: t('sendNotification', defaults.sendNotification),
    at: t('at', defaults.at),
    whenStatusChanges: t('whenStatusChanges', defaults.whenStatusChanges),
    calendarEvent: t('calendarEvent', defaults.calendarEvent),
    todo: t('todo', defaults.todo),
    dueDate: t('dueDate', defaults.dueDate),
  }
}

type Translations = typeof defaultTranslations
