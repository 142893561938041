import ResourceManager, { ResourceType } from '../../components/ResourceManager/ResourceManager'
import { TodoViewModel } from '../api/todo'
import { useTodoMutations } from '../hooks/use-todo-mutations'
import { useI18n } from '../../hooks/use-i18n'
import { StringMap } from '../../types/common'

const TodoProjectWidget = ({ todo, projectOptions }: Props) => {
  const { updateProject } = useTodoMutations()
  const translations = useTranslations(defaultTranslations)
  const todoProject = todo.project ? [{ id: todo.project.id, name: todo.project.title }] : []
  const onAddProject = (project: string) => updateProject(todo.id, { projectId: project })
  const onRemoveProject = () => updateProject(todo.id, { projectId: null })

  return (
    <ResourceManager
      title={translations.title}
      resourceOptions={projectOptions}
      resources={todoProject}
      canAddResource={todo.canUpdate}
      canRemoveResource={todo.canUpdate}
      onAddResource={onAddProject}
      onRemoveResource={onRemoveProject}
      emptyOptionsMessage={translations.emptyOptionsMessage}
      emptyResourcesMessage={translations.emptyResourcesMessage}
      isMultipleSelection={false}
    />
  )
}

const useTranslations = (defaults = defaultTranslations) => {
  const { translations: t } = useI18n('todo')
  const translations = (t?.projectWidget || {}) as StringMap

  const {
    title = defaults.title,
    emptyOptionsMessage = defaults.emptyOptionsMessage,
    emptyResourcesMessage = defaults.emptyResourcesMessage,
  } = translations

  return {
    title,
    emptyOptionsMessage,
    emptyResourcesMessage,
  }
}

const defaultTranslations = {
  title: 'Project',
  emptyOptionsMessage: 'No projects to connect this todo',
  emptyResourcesMessage: 'This todo is not connected to any project',
}

type Props = {
  todo: TodoViewModel
  projectOptions: ResourceType[]
}
export default TodoProjectWidget
